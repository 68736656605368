import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  handleActiveTab,
  handleSwapTabs,
  handleRemoveDiamondViewEdit
} from "../actions/header/headerActions";
import ChooseYourSetting from "../component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "../component/ApplicationPreview/SelectYourDiamond";
import PendantCompleteIcon from "../assets/images/icons/pendantchoose_setting.svg";
import PendantCompleteIconSetting from "../assets/images/icons/complete__pendant.svg";
import CompleteYourRing from "../component/ApplicationPreview/CompleteYourRing";
import {
  handleComaparePageIds,
  handleHideDetailsPage as handleHideDiamondDetailsPage,
} from "../actions/selectYourDiamond/selectYourDiamond";
import { useLocation, useNavigate } from "react-router-dom";
import { handleHideEditChooseThisMounting } from "../actions/chooseYourEarning/chooseYourEarningActions";
import {
  handleSetPage
} from "../actions/PageNavigations/PageNavigations";
import traslate from "../i18n/translate";

export const Header = () => {
  const dispatch = useDispatch();
  const currentTab = useSelector(state => state.headerReducer);
  const search = useLocation().search;
  const navigate = useNavigate();
  const ischooseYourSetting = new URLSearchParams(search).get("ischooseYourSetting");
  const isCompleteYourRing = new URLSearchParams(search).get("iscompleteyourring");
  const isSocialPage = new URLSearchParams(search).get("isSocialPage");
  const paramdealerId = new URLSearchParams(search).get("DealerID");
  const DiamondId = new URLSearchParams(search).get("DiamondId");
  const pageName = new URLSearchParams(search).get("pageName");
  const socialshapeFilters = new URLSearchParams(search).get("shapeFilters");
  const socialtxtCutlet = new URLSearchParams(search).get("txtCutlet");
  const socialtxtClarity = new URLSearchParams(search).get("txtClarity");

  const sociaColorPriority = new URLSearchParams(search).get("intColorPriority");
  const chooseYourEarning = useSelector(
    state => state.handleChooseYourEarningStudSetting
  );

  const [activeTab, setActiveTab] = useState("");
  const [tabDetails, setTabDetails] = useState([]);
  const previewcolor = useSelector(state => state.previewColorReducer);
  const [toggle, setToggle] = useState(true);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: ""
  });
  const [choosetabdiamondEarning, setChooseTabDiamondEarning] = useState(false);
  useEffect(() => {

    if (chooseYourEarning) {
      if (chooseYourEarning.isViewChooseMountingPage) {
        setChooseTabDiamondEarning(true);
      } else {
        setChooseTabDiamondEarning(false);
      }
    }
  }, [chooseYourEarning]);

  const handleTabClick = (tab, index) => {
    dispatch(handleComaparePageIds(""));

    if (choosetabdiamondEarning) {
      let tabDetailsArray = tabDetails;
      if (index == tabDetailsArray.length - 1) {
        let temp = tabDetailsArray[0];
        tabDetailsArray[0] = tabDetailsArray[1];
        tabDetailsArray[1] = temp;
        dispatch(handleSwapTabs(tabDetailsArray));
        dispatch(handleActiveTab(tab));
      }

      if (tab == "completeYourRing") {
        if (currentTab) {
          if (currentTab.isSettingViewEdit && currentTab.isDiamondViewEdit) {
            dispatch(handleActiveTab(tab));
          }
        }
      }
      dispatch(handleActiveTab(tab));
    }
  };

  useEffect(() => {
    if (previewcolor) {
      if (previewcolor.buttonColor) {
        setVarColorObj({
          ...varColorObj,
          callToActionColor: previewcolor.buttonColor.callToActionColor,
          hoverColor: previewcolor.buttonColor.hoverColor,
          columnHeaderAccentColor:
            previewcolor.buttonColor.columnHeaderAccentColor,
          linkColor: previewcolor.buttonColor.linkColor,
        });
        setToggle(previewcolor.toogle);
        lightOrDark(previewcolor.buttonColor.columnHeaderAccentColor, "header");
        lightOrDark(previewcolor.buttonColor.callToActionColor, "button");
        lightOrDark(previewcolor.buttonColor.hoverColor, "hover");
      }
    }
  }, [previewcolor.toogle, toggle]);

  useEffect(() => {
    if (currentTab) {
      if (currentTab.activeTab) {
        setActiveTab(currentTab.activeTab);
      }
      if (currentTab.tabDetails) {
        setTabDetails(currentTab.tabDetails);
      }
    }
  }, [currentTab, currentTab.isDiamondViewEdit, currentTab.isSettingViewEdit]);

  const handleViewDiamond = () => {
    let data = JSON.parse(localStorage.getItem("selectedDiamondData"))
    navigate(
      "/viewDiamondDetails" +
      `?isSocialPage=True&isselectyourdiamond=false&DealerID=${paramdealerId}&diamondId=${DiamondId}&pageNo=1&isAllowed=True&pageName=${pageName}&shapeFilters=${socialshapeFilters}&txtCutlet=${data.txtCutGrade}&txtClarity=${socialtxtClarity}&intColorPriority=${sociaColorPriority}`
    );
  };
  const handleEditTabChooseYourMounting = () => {
    navigate(
      "/chooseYourSetting" +
      `?ischooseYourSetting=True&isselectyourdiamond=false&DealerID=${paramdealerId}&DiamondId=${DiamondId}&pageName=${pageName}&shapeFilters=${socialshapeFilters}&txtCutlet=${socialtxtCutlet}&txtClarity=${socialtxtClarity}&intColorPriority=${sociaColorPriority}`
    );

    dispatch(handleHideEditChooseThisMounting());
  };

  const handleEditDiamond = () => {
    var newpageName =
      pageName == "Lab"
        ? "Lab"
        : pageName == "Mined"
          ? "Mined"
          : pageName;

    dispatch(handleSetPage(newpageName));
    if (pageName == "Lab") {
      navigate("/Lab" + `?DealerLink=${paramdealerId}`);
    } else {
      navigate("/Mined" + `?DealerLink=${paramdealerId}`);
    }
    dispatch(handleHideDiamondDetailsPage());
    dispatch(handleRemoveDiamondViewEdit());
  };

  function lightOrDark(color, type) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
        } else if (type == "button") {
        } else {
        }
      } else {
        if (type == "header") {
        } else if (type == "button") {
        } else {
        }
      }
    }
  }

  return (
    <>
      <div class="bcontainer" id="topHeader">
        <div class="breadcrumb">
          {tabDetails &&
            tabDetails.map((tab, index) => {
              return (
                <li
                  className={ischooseYourSetting != "True" && isCompleteYourRing != "True" && "active"}
                  onClick={() => handleTabClick(tab.tabValue)}
                >
                  <div className="left__block">
                    <span className="circle">1</span>
                    <div className="breadcrumb__content">
                      <p className="mb-0">{tab.tabInitial}</p>
                      <p className="subheading m-0">{tab.tabName}</p>
                    </div>
                  </div>


                  <div className="right__block">
                    {ischooseYourSetting == "True" || isCompleteYourRing == "True" ? (
                      <>
                        {" "}
                        <span onClick={handleViewDiamond}>{traslate("View")}</span>
                        <span>|</span>
                        <span onClick={handleEditDiamond}>{traslate("Edit")}</span>
                      </>
                    ) : isSocialPage == "True" ? (
                      <>
                        {" "}
                        {/* <span onClick={handleEditDiamond}>Edit</span> */}
                      </>
                    ) : null}
                    <img src={tab.imgSrc} />
                  </div>

                </li>
              );
            })}
          {ischooseYourSetting == "True" ? (
            <li
              className={ischooseYourSetting == "True" && "active"}
            >
              <div className="left__block">
                <span className="circle">2</span>
                <div className="breadcrumb__content">
                  <p className="mb-0">Choose Your</p>
                  <p className="subheading m-0">Setting</p>
                </div>
              </div>
              <div className="right__block">
                {isCompleteYourRing == "True" ? (
                  <>
                    {" "}
                    <span onClick={handleEditTabChooseYourMounting}>{traslate("Edit")}</span>
                  </>
                ) : null}
                <img src={PendantCompleteIcon} />
              </div>
            </li>
          ) : (
              <li>
                <div className="left__block">
                  <span className="circle">2</span>
                  <div className="breadcrumb__content">
                    <p className="mb-0">{traslate("Choose Your")}</p>
                    <p className="subheading m-0">{traslate("Setting")}</p>
                  </div>
                </div>
                <div className="right__block">
                  {isCompleteYourRing == "True" ? (
                    <>
                      {" "}
                      <span onClick={handleEditTabChooseYourMounting}>{traslate("Edit")}</span>
                    </>
                  ) : null}
                  <img src={PendantCompleteIcon} />
                </div>

              </li>
            )}

          {isCompleteYourRing == "True" ? (
            <li
              className={isCompleteYourRing == "True" && "active"}
              onClick={() => handleTabClick("completeYourRing")}
            >
              <div className="left__block">
                <span className="circle">3</span>
                <div className="breadcrumb__content">
                  <p className="mb-0">{traslate("Complete Your")}</p>
                  <p className="subheading m-0">{traslate("Pendant")}</p>
                </div>
              </div>
              <div className="right__block">
                <img src={PendantCompleteIconSetting} />
              </div>
            </li>
          ) : (
              <li>
                <div className="left__block">
                  <span className="circle">3</span>
                  <div className="breadcrumb__content">
                    <p className="mb-0">{traslate("Complete Your")}</p>
                    <p className="subheading m-0">{traslate("Pendant")}</p>
                  </div>
                </div>
                <div className="right__block">
                  <img src={PendantCompleteIconSetting} />
                </div>
              </li>
            )}
        </div>
      </div>
      {activeTab == "chooseYourSetting" && (
        <ChooseYourSetting />
      )}
      {activeTab == "selectYourDiamond" && (
        <SelectYourDiamond />
      )}
      {activeTab == "completeYourRing" && (
        <CompleteYourRing />
      )}
    </>
  );
};

export default Header;
