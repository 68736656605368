import { httpApps } from "../helper/http-common";

class ChooseViewDetailsServices {
    PendantBuilderDropHint(inputData) {
        return httpApps.post("PendantBuilderForML/PendantBuilderDropHint", inputData);
    }
    PendantBuilderRequestMoreInfo(inputData) {
        return httpApps.post("PendantBuilderForML/PendantBuilderRequestMoreInfo", inputData);
    }
    PendantBuilderEmailToFriend(inputData) {
        return httpApps.post("PendantBuilderForML/PendantBuilderEmailToFriend", inputData);
    }
    PendantBuilderEmailToFriend(inputData) {
        return httpApps.post("PendantBuilderForML/PendantBuilderEmailToFriend", inputData);
    }
    PendantBuilderScheduleviewing(inputData) {
        return httpApps.post("PendantBuilderForML/PendantBuilderScheduleviewing", inputData);
    }
    GetDealerLocationData(inputData) {
        return httpApps.post("DiamondLinkForML/GetDealerLocationData", inputData);
    }
    PendantBuilderLoadDiamonds(inputData) {
        return httpApps.post("PendantBuilderForML/PendantBuilderLoadDiamonds", inputData);
    }
    BindTimeDropDown(inputData) {
        return httpApps.post("studBuilderForML/BindTimeDropDown", inputData);
      }
    
}


export default new ChooseViewDetailsServices();
