import { httpApps } from "../helper/http-common";

class CompleteYourRingService {
  SaveDropHint(inputData) {
    return httpApps.post("RingBuilderForML/SaveDropHint", inputData);
  }
  SaveRequestInfo(inputData) {
    return httpApps.post("RingBuilderForML/SaveRequestInfo", inputData);
  }
  SaveEmailAFriend(inputData) {
    return httpApps.post("RingBuilderForML/SaveEmailAFriend", inputData);
  }
  SaveScheduleAViewing(inputData) {
    return httpApps.post("RingBuilderForML/SaveScheduleAViewing", inputData);
  }

  GetAppPrevData(inputData) {
    return httpApps.post("RingBuilderForML/GetAppPrevData", inputData);
  }
  GetStudDropDownData(inputData) {
    return httpApps.post("studBuilderForML/GetStudDropDownData", inputData);
  }
  LoadSettingsDetails(inputData) {
    return httpApps.post("PendantBuilderForML/LoadSettingsDetails", inputData);
  }
  LoadDiamondDetails(inputData) {
    return httpApps.post("PendantBuilderForML/LoadDiamondDetails", inputData);
  }
  ChangeProductConfigurationPend(inputData) {
    return httpApps.post(
      "PendantBuilderForML/ChangeProductConfigurationPend",
      inputData
    );
  }
}

export default new CompleteYourRingService();
